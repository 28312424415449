import React from 'react';
import { PageProps } from 'gatsby';
import Layout from '@/components/Layout';
import Hero from '@/components/Hero';
import InfoSection from '@/components/InfoSection';
import Estates from '@/components/Estates';
import Articles from '@/components/Articles';

const Home: React.FC<PageProps> = () => (
  <Layout active={1}>
    <Hero/>
    <InfoSection/>
    <Estates full={false}/>
    <Articles amount={3} bottom={false}/>
  </Layout>
);

export default Home;
