import React, { Component } from 'react'

export default class InfoSection extends Component {
    render() {
        return (
            <section className="info">
                <div className="p-margin">
                    <div className="info__splitter split-50">
                        <div className="info__splitter__content">
                            <div className="v-center">
                                <h1 className="fs-mont-sh">Bostäder i Ånäset och Burträsk.</h1>
                                <p>{`Vi är det lilla fastighetsbolaget som äger och förvaltar bostäder i Burträsk och Ånäset.

                                Med goda kommunikationsmöjligheter till Skellefteå respektive
                                Umeå möjliggörs ett bra alternativ till ett något mer avskilt boende.
                                `}</p>
                                
                            </div>
                        </div>
                        <div className="info__splitter__thumbnail">
                            <img src="/static/images/example-photo.jpg"/>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
