import React, { Component } from 'react'
import isMobile from 'ismobilejs'

export default class Hero extends Component {
    render() {
        return (
            <section className="hero">
                <div className="hero__overlay">
                    <div className="hero__overlay__centerer v-center">
                        <img src="/static/images/logos/white-logo-text.png"/>
                    </div>
                </div>
            </section>
        )
    }
}
